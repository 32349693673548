/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import 'slick-carousel/slick/slick.css'

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';

import 'swiper/css';
import 'aos/dist/aos.css';

const browserHistory = createBrowserHistory();


const App = () => {
  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
};

export default App;
