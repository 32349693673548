export const reviews = [
  {
    authorName: 'Jim Leary',
    authorOccupation: 'Client',
    feedback:
      'Rick Samples is a true professional!!! He has done many jobs for us and one in particular our Basement in the new home that My wife and I purchased. Rick and his crew got the job done in a timely manner and to the standards that you would expect for such a large investment. The work was top notch and worth every penny.',
  },
  {
    authorName: 'Beth Rodgers',
    authorOccupation: 'Real Estate Agent, Howard Hanna',
    feedback:
      'I’ve referred Rick Samples to family, friends and clients for a number of years.  He is knowledgeable, professional, and fair with his estimates.',
  },
  {
    authorName: 'Penny Myers',
    authorOccupation: 'Client',
    feedback:
      'Good contractors are often hard to come by! He recently designed and remodeled two full bathrooms for us and we could not be happier! If you are looking for a trustworthy, reliable, and detail oriented contractor, Rick is your guy!',
  },
  {
    authorName: 'Justin Aikens',
    authorOccupation: 'Real Estate Agent, KW Chervenic Realty',
    feedback:
        'Rick is very valuable to my business, as a real estate agent, and to me, personally, as a landlord. Rick is very knowledgeable in electric, plumbing, and any other projects I can throw his way. I trust Rick 100% to make the necessary repairs and he is always fair on price.',
  },
  {
    authorName: 'Jason O\'Connor',
    authorOccupation: 'Client',
    feedback:
        'I have used RSMA, LLC on many occasions in the past on various types of project and have found them to be very conscientious and thorough in their work with excellent attention to detail. I would happily recommend Rick Samples and RSMA, LLC as a trustworthy and sensibly priced contractor. A rare breed in today’s market place.',
  },
];
