import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const Process = props => {
  const { className, ...rest } = props;

  return (
    <div className={clsx('jarallax', className)} {...rest}>
      <SectionHeader
        title="Our process"
        titleVariant="h2"
        subtitle="We are a very small team of contractors. Unlike teams from larger companies, we will treat your project as ours. We will walk you through our smooth and simple process and let you know why RSMA is the right company for you."
        ctaGroup={[
          <Button variant="contained" color="primary" size="large" component={'a'} href="/about">
            About us
          </Button>,
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

Process.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Process;
