import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import clsx from 'clsx';
import Swiper from 'swiper';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardReview } from 'components/organisms';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const useStyles = makeStyles(() => ({
  swiperContainer: {
    width: '100%',
    maxWidth: 500,
  },
}));

const Reviews = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  // React.useEffect(() => {
  //   new Swiper('.swiper-container', {
  //     slidesPerView: 1,
  //     spaceBetween: 0,
  //     pagination: {
  //       el: '.swiper-container .swiper-pagination',
  //       type: 'bullets',
  //       clickable: true,
  //     },
  //     autoplay: {
  //       delay: 6000,
  //     },
  //   });
  // });





  return (
    // <div className={className} data-aos="fade-up" {...rest}>
    //   <div className={clsx('swiper-container', classes.swiperContainer)}>
    //     <div className="swiper-wrapper" >
    //       {data.map((item, index) => (
    //         <CardReview
    //           key={index}
    //           className={'swiper-slide'}
    //           noBorder
    //           noShadow
    //           text={item.feedback}
    //           icon={
    //             <IconAlternate
    //               color={colors.indigo}
    //               fontIconClass="fas fa-quote-right"
    //             />
    //           }
    //           authorName={item.authorName}
    //           authorTitle={item.authorOccupation}
    //           authorPhoto={item.authorPhoto}
    //         />
    //       ))}
    //     </div>
    //     <div className="swiper-pagination" />
    //   </div>
    // </div>
    <div>
      <Carousel 
      responsive={responsive}
      showDots={true}
      >
      {data.map((item, index) => (
        <div style={{marginLeft:20}}>
            <CardReview
              key={index}
              className={'swiper-slide'}
              noBorder
              noShadow
              text={item.feedback}
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName={item.authorName}
              authorTitle={item.authorOccupation}
              authorPhoto={item.authorPhoto}
            />
        </div>
      ))}
      </Carousel>
    </div>
  );
};

Reviews.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Reviews;



