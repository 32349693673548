import React from 'react'
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: '/assets/misc_pics/misc-1.webp',
    thumbnail: '/assets/misc_pics/misc-1.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/misc_pics/misc-2.webp',
    thumbnail: '/assets/misc_pics/misc-2.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/misc_pics/misc-3.webp',
    thumbnail: '/assets/misc_pics/misc-3.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/misc_pics/misc-4.webp',
    thumbnail: '/assets/misc_pics/misc-4.webp',
    originalHeight: 500,
    originalWidth: 500,
  },

];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />
  }
}

function Misc() {
  return (
    <div>
      <div>
      <SectionHeader
          title="Miscellaneous"
          titleVariant='h1'
        />
      </div>
      <div>
          <div>
            <SectionHeader
              title={
                <span>
                  <Typography component="span" variant="inherit" color="primary">Tackling</Typography>{' '}
                  a range of projects with precision and attention to detail.
                </span>
              }
            />
          </div>
          <div>
            <MyGallery />
          </div>
      </div>
    </div>
  )
}

export default Misc