import { SectionHeader } from 'components/molecules'
import React from 'react'
import { Image } from 'components/atoms';
import { Grid, Typography } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: '/assets/kitchen_pics/kitchen-1.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-1.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/kitchen_pics/kitchen-2.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-2.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/kitchen_pics/kitchen-3.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-3.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/kitchen_pics/kitchen-4.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-4.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/kitchen_pics/kitchen-5.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-5.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original: '/assets/kitchen_pics/kitchen-6.webp',
    thumbnail: '/assets/kitchen_pics/kitchen-6.webp',
    originalHeight: 500,
    originalWidth: 500,
  },

];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />
  }
}

function Kitchens() {
  return (
    <div>
      <div>
        <SectionHeader
          title="Kitchens"
          titleVariant='h1'
        />
      </div>
      <div>
        <SectionHeader 
          title={
            <span>
              <Typography component="span" variant="inherit" color="primary">Elevate</Typography>{' '}
              your culinary space with our expert kitchen renovations.
            </span>
          }
        />
      </div>
      <MyGallery />
    </div>
  )
}

export default Kitchens