import { colors } from '@material-ui/core';

export const jobs = [
  {
    color: colors.grey[500],
    title: 'Electrical, including outdoor lighting',
    jobTitle: 'Electrical',
    type: 'Outdoor',
    location: 'Indoor',
    date: '3 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.blue[500],
    title: 'Outdoor lighting',
    jobTitle: 'Outdoor lighting',
    type: 'Outdoor',
    location: 'Indoor',
    date: '3 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.purple[500],
    title: 'Plumbing',
    jobTitle: 'Plumbing',
    type: 'Outdoor',
    location: 'Indoor',
    date: 'Just now',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
  {
    color: colors.amber[500],
    title: 'Painting',
    jobTitle: 'Painting',
    type: 'Outdoor',
    location: 'Indoor',
    date: '1 week ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/atlassian.svg',
    companyName: 'Atlassian',
  },
  {
    color: colors.indigo[500],
    title: 'Carpentry',
    jobTitle: 'Carpentry',
    type: 'Outdoor',
    location: 'Indoor',
    date: '2 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/dropbox.svg',
    companyName: 'Dropbox',
  },
  {
    color: colors.pink[500],
    title: 'Drywall and drywall repair',
    jobTitle: 'Drywall and drywall repair',
    type: 'Outdoor',
    location: 'Indoor',
    date: '1 month ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
  {
    color: colors.green[500],
    title: 'Flooring Install and Repair',
    jobTitle: 'Flooring Install and Repair',
    type: 'Outdoor',
    location: 'Indoor',
    date: '4 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/google-drive.svg',
    companyName: 'Google Drive',
  },
  {
    color: colors.deepOrange[500],
    title: 'Tile',
    jobTitle: 'Tile',
    type: 'Outdoor',
    location: 'Indoor',
    date: '5 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/mailchimp.svg',
    companyName: 'Mail Chimp',
  },
  {
    color: colors.red[500],
    title: 'Bathroom remodel',
    jobTitle: 'Bathroom remodel',
    type: 'Outdoor',
    location: 'Indoor',
    date: '2 weeks ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.purple[500],
    title: 'Kitchen remodel',
    jobTitle: 'Kitchen remodel',
    type: 'Outdoor',
    location: 'Indoor',
    date: 'Just now',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Placeholder Title.',
    subtitle: 'Placeholder Subtitle',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat!',
  },
  {
    id: 'faq-2',
    title: 'Placeholder Title.',
    subtitle: 'Placeholder Subtitle',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat!',
  },
];
