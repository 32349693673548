/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WithLayout from 'WithLayout';
import {
  Main as MainLayout,
    Minimal as MinimalLayout,
} from './layouts';

import {
    Home as HomeView,
    NotFound as NotFoundView,
    ContactPage as ContactPageView,
    About as AboutView,
    Services as ServiceView,
    Bathrooms as BathroomsView,
    Kitchens as KitchensView,
    Basements as BasementsView,
    Lighting as LightingView,
    Misc as MiscView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={HomeView}
            layout={MainLayout}
          />
        )}
      />
        <Route
            exact
            path="/contact"
            render={matchProps => (
                <WithLayout
                    {...matchProps}
                    component={ContactPageView}
                    layout={MainLayout}
                />
            )}
        />
        <Route
            exact
            path="/about"
            render={matchProps => (
                <WithLayout
                    {...matchProps}
                    component={AboutView}
                    layout={MainLayout}
                />
            )}
        />
        <Route
            exact
            path="/services"
            render={matchProps => (
                <WithLayout
                    {...matchProps}
                    component={ServiceView}
                    layout={MainLayout}
                />
            )}
        />
        <Route
            exact
            path="/not-found"
            render={matchProps => (
                <WithLayout
                    {...matchProps}
                    component={NotFoundView}
                    layout={MinimalLayout}
                />
            )}
        />
        <Route 
          exact
          path="/bathrooms"
          render={matchProps => (
            <WithLayout
              {...matchProps}
              component={BathroomsView}
              layout={MainLayout}
              />
          )}
        />
        <Route 
          exact
          path="/basements"
          render={matchProps => (
            <WithLayout
              {...matchProps}
              component={BasementsView}
              layout={MainLayout}
              />
          )}
        />
        <Route 
          exact
          path="/kitchens"
          render={matchProps => (
            <WithLayout
              {...matchProps}
              component={KitchensView}
              layout={MainLayout}
              />
          )}
        />
        <Route 
          exact
          path="/lighting"
          render={matchProps => (
            <WithLayout
              {...matchProps}
              component={LightingView}
              layout={MainLayout}
              />
          )}
        />
        <Route 
          exact
          path="/misc"
          render={matchProps => (
            <WithLayout
              {...matchProps}
              component={MiscView}
              layout={MainLayout}
              />
          )}
        />    
        <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
