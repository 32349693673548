import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { SectionHeader, DescriptionCta } from 'components/molecules';

const Application = props => {
  const { className, ...rest } = props;

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Reach out to us in order to find out more"
        subtitle="In order to set up a time for an estimate, or inquire about any of our services, please contact us."
        fadeUp
        align="left"
      />
      <DescriptionCta
        title="Reach out today with any and all questions"
        primaryCta={
          <Button variant="contained" color="primary" size="large" component={'a'} href={'/contact'}>
            Contact
          </Button>
        }
        align={'left'}
      />
    </div>
  );
};

Application.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Application;
