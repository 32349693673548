import React from 'react'
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images =[
  {
    original:'/assets/lighting_pics/lighting-1.webp',
    thumbnail:'/assets/lighting_pics/lighting-1.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original:'/assets/lighting_pics/lighting-2.webp',
    thumbnail:'/assets/lighting_pics/lighting-2.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
  {
    original:'/assets/lighting_pics/lighting-3.webp',
    thumbnail:'/assets/lighting_pics/lighting-3.webp',
    originalHeight: 500,
    originalWidth: 500,
  },
];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />
  }
}

function Lighting() {
  return (
    <div>
      <div>
        <SectionHeader
          title="Lighting"
          titleVariant='h1'
        />
      </div>
      <div>
        <SectionHeader 
          title={
            <span>
              <Typography component="span" variant="inherit" color="primary">Illuminate</Typography>{' '}
               your space with our expert lighting solutions.
            </span>
          }
        />
      </div>
      <MyGallery />
    </div>
  )
}

export default Lighting