import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import validator from 'validator';
import InputMask from "react-input-mask";
// import {key, url} from "../../mailgun/api";
// import {getHtml} from "../../mailgun/template";
import green from "@material-ui/core/colors/green";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";

// const mailgun = require("mailgun-js");

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 550,
    margin: `0 auto`,
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  helperText: {
    backgroundColor: '#2D3748',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Form = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const [fail, setFail] = useState(false);
  const [sent, setSent] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success || sent,
  });

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  function handleSubmit(event) {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (sent) {
      setResend(true);
      setLoading(false);
      return;
    }

    if (!validateForm()) {
      setShowError(true);
      setLoading(false);
      return;
    }

    // const mg = mailgun({apiKey: key, domain: url});
    // const data = {
    //   from: name + ' <noreply@rsmallc.com>',
    //   to: 'mattbendel60@gmail.com',
    //   // to: 'rsamples130@gmail.com',
    //   subject: 'Website Response - ' + name,
    //   text: `${name} - ${phone} - ${email}\n${message}`,
    //   html: getHtml(name, email, phone, message)
    // };
    // mg.messages().send(data, function (error, body) {
    //   if (error) {
    //     setTimeout(() => {
    //       setLoading(false);
    //       setFail(true);
    //     }, 1500);
    //   } else {
    //     setTimeout(() => {
    //       setLoading(false);
    //       setSuccess(true);
    //       setSent(true);
    // setSent(true)
    //     }, 1500);
    //   }
    // });
  }

  let validateForm = () => {
    return name !== '' && message !== '' && validEmail() && validPhone();
  }

  let validEmail = () => {
    return validator.isEmail(email);
  }

  let validPhone = () => {
    return phone.replace(/ /g,'').length === 10
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (success) {
      setSuccess(false);
    } else if (fail) {
      setFail(false);
    } else {
      setResend(false);
    }
  };

  return (
    <div className={className} {...rest}>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success">
          Your message has been sent.
        </Alert>
      </Snackbar>
      <Snackbar open={sent && resend} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success">
          Your message has already been sent.
        </Alert>
      </Snackbar>
      <Snackbar open={fail} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="error">
          Your message failed to send, please try again.
        </Alert>
      </Snackbar>
      <SectionHeader
        title="Ready to get started?"
        subtitle="Please be sure to mention the service that you are interested in and any details relevant to your inquiry. Also, please specify your preferred mode of contact."
        subtitleProps={{
          variant: 'body1',
          color: 'textPrimary',
        }}
        data-aos="fade-up"
        align={isMd ? 'center' : 'left'}
      />
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Full name
            </Typography>
            <TextField
              placeholder="Your full name*"
              variant="outlined"
              size="medium"
              name="fullname"
              fullWidth
              type="text"
              helperText={showError && name === '' ? "Please enter a name." : ""}
              error={showError && name === ''}
              onInput={ e=>setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
            >
              Phone number
            </Typography>
            <InputMask
                mask="999 999 9999"
                value={phone}
                disabled={false}
                maskChar=" "
            >
              {() => <TextField
                  placeholder="Your phone number*"
                  variant="outlined"
                  size="medium"
                  name="phone"
                  fullWidth
                  type="phone"
                  helperText={showError && !validPhone() ? "Please enter a valid phone number." : ""}
                  error={showError && !validPhone()}
                  onInput={e => setPhone(e.target.value)}
              />}
            </InputMask>
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              E-mail
            </Typography>
            <TextField
              placeholder="Your e-mail address*"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="email"
              helperText={showError && !validEmail() ? "Please enter a valid email." : ""}
              error={showError && !validEmail()}
              onInput={ e=>setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Message
            </Typography>
            <TextField
              placeholder="Your question about our services*"
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
              helperText={showError && message === '' ? "Please enter a question for us to reply to." : ""}
              error={showError && message === ''}
              onInput={ e=>setMessage(e.target.value)}
            />
          </Grid>
          <Grid item container justify="center" xs={12}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                className={buttonClassname}
                disabled={loading}
              >
                submit
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

Form.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Form;
