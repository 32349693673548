import React from 'react';
import { Divider } from '@material-ui/core';
import {Section, SectionAlternate} from 'components/organisms';
import { Form, Hero, Contact } from './components';


const ContactPage = () => (
  <div>
    <Hero />
      {/* <Divider />
      <Section>
        <Form />
    </Section>
    <Divider /> */}
      <Contact />
      <Divider />
  </div>
);

export default ContactPage;
