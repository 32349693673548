import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { SectionHeader, OverlapedImages } from 'components/molecules';

const Process = props => {
  const { data, className, ...rest } = props;

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <SectionHeader
            title={
              <span>
                <Typography component="span" variant="inherit" color="primary">Our services</Typography>{' '}
                cover all of your home improvement needs.
              </span>
            }
            subtitle="Whether you need small repairs or a complete update, we have you covered"
            align="left"
            disableGutter
          />
        </Grid>
        <Grid
          item
          container
          alignContent="center"
          xs={12}
          sm={12}
          md={6}
          data-aos="fade-up"
        >
          <OverlapedImages
            image1={{
              src: '/assets/banner/basement.jpg',
              srcSet: '/assets/banner/basement.jpg 2x',
              alt: '...',
            }}
            image2={{
              src: '/assets/banner/kitchen.jpg',
              srcSet: '/assets/banner/kitchen.jpg 2x',
              alt: '...',
            }}
            image3={{
              src: '/assets/banner/other.jpg',
              srcSet: '/assets/banner/other.jpg 2x',
              alt: '...',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Process.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Process;
