import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import {Section, Parallax, SectionAlternate} from 'components/organisms';
import {
  Welcome,
  Kitchens,
  Process,
  Bathrooms,
  Basements,
  Reviews,
  Contact,
  Others,
} from './components';

import { reviews } from './data';
import { FiArrowDownCircle} from "react-icons/fi";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.blue[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  kitchenSection: {
    backgroundColor: '#0000008a',
  },
  bathroomSection: {
    backgroundColor: '#0000008a',
    // backgroundColor: '#2d402f80',
  },
  bedroomSection: {
    backgroundColor: '#0000008a',
  },
}));

const Home = () => {
  const classes = useStyles();

  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.fullHeight}>
        <Section className={classes.disablePaddingTop}>
          <Welcome />
        </Section>
        {/* <IconAlternate
          shape="circle"
          fontIconClass="chevron-down"
          color={colors.blue}
          size="small"
          className={classes.scrollIcon}
          onClick={() => scrollTo('kitchens')}
        /> */}
        <FiArrowDownCircle 
          size={"3em"}
          color='rgb(124,173,214)'
          onClick={() => scrollTo('kitchens')} 
        />
      </div>
      <Parallax
        backgroundImage="/assets/banner/kitchen.jpg"
        id="kitchens"
      >
        <div className={clsx(classes.fullHeight, classes.kitchenSection)}>
          <Section>
            <Kitchens />
          </Section>
          {/* <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.blue}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('bathrooms')}
            data-aos="fade-up"
          /> */}
          <FiArrowDownCircle 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('bathrooms')} 
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage="/assets/banner/bathroom.jpg"
        id="bathrooms"
      >
        <div className={clsx(classes.fullHeight, classes.bathroomSection)}>
          <Section>
            <Bathrooms />
          </Section>
          {/* <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.blue}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('basements')}
            data-aos="fade-up"
          /> */}
          <FiArrowDownCircle 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('basements')} 
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage="/assets/banner/basement.jpg"
        id="basements"
      >
        <div className={clsx(classes.fullHeight, classes.bedroomSection)}>
          <Section>
            <Basements />
          </Section>
          {/* <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.blue}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('process')}
            data-aos="fade-up"
          /> */}
          <FiArrowDownCircle 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('other')} 
          />
        </div>
      </Parallax>
      <Parallax
          backgroundImage="/assets/banner/other.jpg"
          id="other"
      >
        <div className={clsx(classes.fullHeight, classes.bedroomSection)}>
          <Section>
            <Others />
          </Section>
          {/* <IconAlternate
              shape="circle"
              fontIconClass="fas fa-chevron-down"
              color={colors.blue}
              size="small"
              className={classes.scrollIcon}
              onClick={() => scrollTo('reviews')}
              data-aos="fade-up"
          /> */}
          <FiArrowDownCircle 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('process')}
          />
        </div>
      </Parallax>
      <div className={classes.fullHeight} id="process">
        <Section>
          <Process />
        </Section>
        {/* <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.blue}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('other')}
            data-aos="fade-up"
        /> */}
          <FiArrowDownCircle 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('reviews')} 
          />
      </div>
      <Section id="reviews">
        <Reviews data={reviews} />
      </Section>
      <Divider />
      <SectionAlternate>
        <Contact />
        {/* <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-up"
            color={colors.blue}
            size="small"
            className={clsx(classes.scrollIcon, classes.scrollTopIcon)}
            onClick={() => scrollTo('root')}
            data-aos="fade-up"
        /> */}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <FaRegArrowAltCircleUp 
            size={"3em"}
            color='rgb(124,173,214)'
            onClick={() => scrollTo('root')}
          />
        </div>
      </SectionAlternate>
      <Divider />
    </div>
  );
};

export default Home;
