import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button, Typography} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const Welcome = props => {
  const { className, ...rest } = props;

  return (
    <div className={clsx('jarallax', className)} {...rest}>
      <SectionHeader
        title={<><Typography component="span" variant="inherit" color="primary">Our company</Typography> is here to handle all of your contracting needs</>}
        titleVariant="h2"
        subtitle="We specialize in electrical, plumbing, and home maintenance, servicing clients throughout Summit and Stark County."
        ctaGroup={[
          <Button variant="outlined" color="primary" size="large" component="a" href="/contact">
            Contact
          </Button>,
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

Welcome.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Welcome;
