export const gallery = [
  {
    image: {
      src: '/assets/about_gallery/vert1.jpg',
      srcSet: '/assets/about_gallery/vert1.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
  {
    image: {
      src: '/assets/about_gallery/horiz1.jpg',
      srcSet: '/assets/about_gallery/horiz1.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: '/assets/about_gallery/horiz2.jpg',
      srcSet: '/assets/about_gallery/horiz2.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: '/assets/about_gallery/vert2.jpg',
      srcSet: '/assets/about_gallery/vert2.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
];
