import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import { Section, SectionAlternate } from 'components/organisms';

import {
  Process,
  Jobs,
  Application,
  Faq,
} from './components';

import { jobs, faq } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.alternate.main} 0%)`,
  },
}));

const Services = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section>
        <Process />
      </Section>
        <Divider />
        <SectionAlternate>
        <Jobs data={jobs} />
      </SectionAlternate>
        <Divider />
        <Section>
        <Application />
      </Section>
        <Divider />
        <SectionAlternate innerNarrowed>
        {/* <Faq data={faq} /> */}
      </SectionAlternate>
        <Divider />
    </div>
  );
};

export default Services;
