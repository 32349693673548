import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Topbar, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    service: {
      title: 'Services',
      id: 'services',
      hasDropdown: false,
      href: '/services',
    },
    about: {
      title: 'About Us',
      id: 'about',
      hasDropdown: false,
      href: '/about',
    },
    projects: {
      title: 'Completed Projects',
      id: 'completed-projects',
      hasDropdown: true,
      href: '/completed-projects',
      children: {
        project: {
          groupTitle: 'Completed Projects',
          pages: [
            {
              title: 'Bathrooms',
              href: '/bathrooms',
            },
            {
              title: 'Kitchens',
              href: '/kitchens',
            },
            {
              title: 'Basements',
              href: '/basements',
            },
            {
              title: 'Lighting',
              href: '/lighting',
            },
            {
              title: 'Miscellaneous',
              href: '/misc',
            },
          ],
        },
      },
    },
  };

  const d = new Date();
  let year = d.getFullYear();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        pages={pages}
        themeMode={themeMode}
        themeToggler={themeToggler}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Divider />
        {children}
      </main>
      <div style={{height: "60px", display: "flex", justifyContent: "center"}}>
        <h4 style={{alignSelf: "center"}}>
          © {year} RSMA LLC
        </h4>
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
