import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: '/assets/bathroom_pics/bathroom-1.webp',
        thumbnail: '/assets/bathroom_pics/bathroom-1.webp',
        originalHeight: 500,
        originalWidth: 500,
    },
    {
        original: '/assets/bathroom_pics/bathroom-2.webp',
        thumbnail: '/assets/bathroom_pics/bathroom-2.webp',
        originalHeight: 500,
        originalWidth: 500,
    },
    {
        original: '/assets/bathroom_pics/bathroom-3.webp',
        thumbnail: '/assets/bathroom_pics/bathroom-3.webp',
        originalHeight: 500,
        originalWidth: 500,
    },
    {
        original: '/assets/bathroom_pics/bathroom-4.webp',
        thumbnail: '/assets/bathroom_pics/bathroom-4.webp',
        originalHeight: 500,
        originalWidth: 500,
    },
    {
        original: '/assets/bathroom_pics/bathroom-5.webp',
        thumbnail: '/assets/bathroom_pics/bathroom-5.webp',
        originalHeight: 500,
        originalWidth: 500,
    },
];

class MyGallery extends React.Component {
    render() {
      return <ImageGallery items={images} />
    }
  }

function Bathrooms() {
  return (
    <div>
      <div>
        <SectionHeader
          title="Bahtrooms"
          titleVariant='h1'
        />
      </div>
      <div>
        <SectionHeader 
          title={
            <span>
              <Typography component="span" variant="inherit" color="primary">Crafting functional</Typography>{' '}
              and stylish bathrooms tailored to your needs.
            </span>
          }
        />
      </div>
      <MyGallery />
    </div>
  )
}


export default Bathrooms